import Appshell from '../../components/Appshell'
import Payment from '../../components/screens/Payment'


const text = {
  note: 'Dobrá voľba, vybrali ste si typ predplatného:',
  title: '6 mesiacov (2/39)',
  description: `Na vašu adresu príde každý mesiac balík dvoch dobrých kníh. Vaša platba bude najviac 39 eur,
  vrátane poštovného (alebo menej, v závislosti od knižného výberu v daný mesiac).`,
}

const SemiAnnually = () => {
  return (
    <Appshell>
      <Payment text={text} type="semi-annually" />
    </Appshell>
  )
}

export default SemiAnnually
